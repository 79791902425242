import { StoreItem } from "../objects/StoreItem";
import { NavItemCard } from "./NavItemCard";

export type ManifestTypeProps = {
    /**
     * appData contains data needed to build up the Apps component.
     */
    data: any;

    /**
     * The url of the previous page
     */
    prevPage: string;

    /**
     * Key 
     */
    groupKey?: number

    /**
     * If the storeitems are for the homepage or for the overviewpages
     */
    home?: boolean;
};

export const ItemList = (props: ManifestTypeProps): JSX.Element => {
    // Get store items
    let storeItems = props.data.items.map((item: StoreItem, index: number) => {      
        return <NavItemCard data={item} prevPage={props.prevPage} key={`si-${props.groupKey}-${index}`} cardId={`si-${props.groupKey}-${index}`}/>
    });

    return (
        storeItems
    )
};
