import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { StoreItem } from "./objects/StoreItem";
import { EnvironmentContext } from "./EnvironmentContext";
import { useContext } from "react";
import { getCost } from "./GetCost";
import { Icon } from "./Icons";

/**
 * The props for the detail page card
 */
export type DetailPageCardProps = {

    /**
     * The store item
     */
    storeItem: StoreItem;

    /**
     * Details of the store item
     */
    details: any;

    /**
     * The on click event
     * @returns 
     */
    onClick: () => void;
}

/**
 * Represents the app details card on the detail page
 * @param props - The props
 * @returns The JSX element
 */
export const DetailPageCard = (props: DetailPageCardProps) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const { environment } = useContext(EnvironmentContext);

    /**
     * Check whether the store item needs an update, can be installed or is already installed.
     * @returns status of store item
     */
    const checkUpdate = (installStatus?: "Install" | "Update" | "Installed") => {
        if (!installStatus) {
            return <></>
        }

        if (!environment) {
            return <div className="environment-error">{t("ENTER_ENVIRONMENT")}</div>
        }

        if (installStatus === "Install") {
            return <button onClick={props.onClick} className="button install-button" type="button">
                {t("INSTALL")}
            </button>
        }

        if (installStatus === "Update") {
            return <button onClick={props.onClick} className="button update-button" type="button">
                {t("INSTALL_UPDATE")}
            </button>
        }

        if (installStatus === "Installed") {
            return <div className="status status-installed">
                <span className="icon-tick"></span>{t("INSTALLED")}
            </div>
        }
    };

    return <div className="card">
        <div className="card-top">
            <div className="image">
                <Icon details={props.details} data={props.storeItem} />
            </div>
            <h1>{props.storeItem.name}</h1>
            {props.storeItem.manifestType !== "Block" && <p>{props.storeItem.description}</p>}
            <div className="tag">{t(getCost(props.storeItem.visibility))}</div>
            {auth.isAuthenticated && checkUpdate(props.storeItem.installStatus)}
        </div>
        <div className="card-bottom">
            <dl>
                <dd>{t('NAME', { ns: "common" })}</dd>
                <dt>{props.storeItem.name}</dt>
                <dd>{t('AUTHOR', { ns: "viewer" })}</dd>
                <dt>{props.storeItem.author}</dt>
                <dd>{t("VERSION")}</dd>
                <dt>{props.storeItem.version}</dt>
                <dd>{t("LAST_UPDATE")}</dd>
                <dt>
                    {new Date(props.storeItem.lastUpdatedAt).toLocaleDateString("nl-NL")}
                </dt>
            </dl>
        </div>
    </div>;
}