import { useAuth } from "react-oidc-context";
import { EnvironmentContext } from "../EnvironmentContext";
import { useContext } from "react";
import { StoreItem } from "../objects/StoreItem";
import { GroupedStoreItem } from "../objects/GroupedStoreItem";
import { InstallStoreItemResult } from "../objects/InstallStoreItemResult";

export const useStoreBackend = () => {
    const auth = useAuth();
    const { environment } = useContext(EnvironmentContext);

    /**
     * Retrieve the list of store items
     * @param options - The options
     * @returns List of store items
     */
    const getStoreItems = async (options?: { groupBy?: string, groupByLimit?: number }): Promise<StoreItem[] | GroupedStoreItem[]> => {

        // Construct URL
        const fetchUrl = new URL(`${(window as any)._env_.API_URL}store`);
        fetchUrl.searchParams.append("orderby", "name");
        if (options?.groupBy) {
            fetchUrl.searchParams.append("groupBy", options.groupBy);
        }
        if (options?.groupByLimit) {
            fetchUrl.searchParams.append("groupByLimit", options.groupByLimit.toString());
        }
        if (auth.isAuthenticated) {
            fetchUrl.searchParams.append("target", environment);
        }

        // Load data
        const res = await fetch(fetchUrl, {
            method: "GET",
            headers: getHeaders()
        });

        // Handle error when necessary
        handleError(res);

        const result = await res.json();
        return result;
    }


    /**
     * Retrieve the details of a store item
     * @param id - The id of the store item
     * @returns Details of the store item
     */
    const getStoreDetails = async (id: string, signal?: AbortSignal) => {
        const fetchUrl = new URL(`${(window as any)._env_.API_URL}store/${id}`);
        if (auth.isAuthenticated){
            fetchUrl.searchParams.append("target", environment);
        }  
        
        const res = await fetch(fetchUrl, {
            method: "GET",
            headers: getHeaders(),
            signal
        });
    
        handleError(res);
        return res.json();
    }

    /**
     * Install the store item to the current environment
     * @param id - The id of the store item
     * @returns Details of the store item
     */
    const installStoreItem = async (id: string): Promise<InstallStoreItemResult> => {

        // Construct url and body
        const installUrl = `${(window as any)._env_.API_URL}store/${id}/install`;
        const body = {
            id: id,
            targetUrl: environment,
        };

        // Load data
        const res = await fetch(installUrl, {
            method: "POST",
            body: JSON.stringify(body),
            headers: getHeaders()
        });

        // Handle error when necessary
        handleError(res);

        return {
            status: res.status,
        };
    }

    /**
     * Build the default headers for the fetch request
     * @returns The headers
     */
    const getHeaders = () => {
        const headers: { [key: string]: string } = {};
        headers["Content-Type"] = "application/json";

        if (auth.user?.access_token) {
            headers["Authorization"] = `Bearer ${auth.user?.access_token}`;
        }

        return headers;
    }

    /**
     * Handle the error response from the fetch request
     * @param res - The response from the fetch request
     */
    const handleError = (res: Response) => {
        if (!res.ok) {
            console.error("error: ", res.status, res.statusText);
            throw new Error("Fetch failed");
        }
    }

    return {
        getStoreItems,
        getStoreDetails,
        installStoreItem
    }
}