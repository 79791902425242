import { NavLink } from "react-router-dom";
import { StoreItemCard } from "./StoreItemCard";
import { StoreItem } from "../objects/StoreItem";

export type NavItemCardProps = {

	/**
	 * Contains data needed to build up the object (apps, blocks, extensions)
	 */
	data: StoreItem;

	/**
	 * The url of the previous page
	 */
	prevPage: string;

	/**
	 * Key
	 */
	cardId?: string;
};

/**
 * Render the card for an app, block or extension, which is also a link to the details page
 * @param props 
 * @returns 
 */
export const NavItemCard = (props: NavItemCardProps) => {
	
	return <>
		<NavLink className="card" to={`/${props.data.name}`} state={{ prevPage: props.prevPage, itemId: props.data.id }}>
			<StoreItemCard data={props.data} key={`si-${props.cardId}`} />
		</NavLink>
	</>
};
