import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { storeItemData } from "../StoreItemData";
import { StoreItem } from "../objects/StoreItem";
import { ExtendedCardBottom } from "./ExtendedCardBottom";
import { StoreItemDetails } from "../objects/StoreItemDetails";
import { SimpleCardBottom } from "./SimpleCardBottom";
import { Icon } from "../Icons";

export type StoreItemProps = {
	/**
	 * Contains data needed to build up the object (apps, blocks, extensions)
	 */
	data: StoreItem;

	/**
	 * Key
	 */
	key?: string;
};

export const StoreItemCard = (props: StoreItemProps) => {
	const [details, setDetails] = useState<StoreItemDetails>({
		tags: [],
		icon: "",
	});

	useEffect(() => {
		if (!props.data.id){
			return;
		}
		
		const item = storeItemData.find(i => i.id === props.data.id)?.items[0];
        
        setDetails({
            tags: item?.tags || [props.data.description],
            icon: item?.icon || "",
        });
	}, [props.data.id]);

	return <>
		<div className="card-top">
			<div className="image">
				<Icon details={details} data={props.data} />
			</div>
			<div className="card-content">
				<h3>{props.data.name}</h3>
				<p>
					{details.tags.join(", ")}
				</p>
			</div>
		</div>
		<div className="card-bottom">
			<SimpleCardBottom storeItem={props.data} />
		</div>
	</>
};
